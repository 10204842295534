import React from "react";
import { Affix, Layout, Row, Col } from "antd";
import FeatherIcon from "feather-icons-react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { globalHistory } from "@reach/router";
import style from "./sidebar.module.less";
import { useWindowSize } from "../../../utils/hooks";

import { useTranslation } from "react-i18next";

import { FaInstagram, FaYoutube, FaTelegram } from "react-icons/fa";

const { Content } = Layout;

const DomContent = props => (
  <aside>
    <div className={style.profileAvatar} />
    <div className={`${style.name} centerAlign`}>
      <div className={`${style.boxName} centerAlign`}>
        <h2>{props.t.title}</h2>
      </div>
      <div className={style.resumeDownload}>
        <a href="https://likeobmen.ru/app"> {props.t.btnText} </a>
      </div>

      <ul className={`box ${style.badge} `}>
        {props.t.checkItems.map(item => (
          <li className={`${style.contactBlockItem}`}>
            <span>
              <FeatherIcon size="19" icon="check-circle" />
            </span>
            &nbsp; {item}
          </li>
        ))}
      </ul>

      {/* TODO - facebook, github, социальные иконки - были в конфиге */}

      <div className="centerAlign">
        <a
          style={{ marginRight: 5, fontSize: 20 }}
          href="https://t.me/likeobmen_team"
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <FaTelegram />
        </a>
        <a
          style={{ marginRight: 5, fontSize: 20 }}
          href="https://www.instagram.com/likeobmen_ru/"
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
        <a
          style={{ marginRight: 5, fontSize: 20 }}
          href="https://www.youtube.com/channel/UCp2qde0z2bUpSmofmUixP7w"
          target="_blank"
          label="button"
          rel="noopener noreferrer"
        >
          <FaYoutube />
        </a>
      </div>
    </div>
  </aside>
);

const Sidebar = props => {
  const { t, i18n } = useTranslation("sidebar");

  const contentData = t("sideBarContent", { returnObjects: true });

  const [width] = useWindowSize();
  const { children } = props;
  const { pathname } = globalHistory.location;
  let domContent = <DomContent t={contentData} />;

  if (width > 997) {
    domContent = (
      <Affix offsetTop={0}>
        <DomContent t={contentData} />
      </Affix>
    );
  }

  if (width < 768) {
    domContent = <></>;
    if (pathname === `/${i18n.language}`) {
      domContent = <DomContent t={contentData} />;
    }
  }

  return (
    <>
      <Layout>
        <Content className={`${style.content} ${style.background}`}>
          <Row>
            <Col sm={24} md={9} lg={6} className={style.sidebarContent}>
              {domContent}
            </Col>
            <Col sm={24} md={15} lg={18}>
              <Layout
                className={`${style.background} ${style.boxContent} borderRadiusSection`}
              >
                {children}
              </Layout>
            </Col>
          </Row>
        </Content>
      </Layout>
    </>
  );
};

export const Sidebar404 = props => {
  const { children } = props;
  return (
    <Layout>
      <Content className={`${style.content} ${style.background} `}>
        <Row>
          <Col sm={24} md={24} lg={24}>
            <Layout
              className={`${style.background} ${style.boxContent} ${style.sideBar404Radius}`}
            >
              {children}
            </Layout>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default Sidebar;
